export default function (boxId, wrapper, router) {
    if (document.getElementById(boxId)) {
        const vm = new Vue({
            store: window.store_global || new Vuex.Store({}),
            router,
            render(h) {
                return h(wrapper);
            },
        });
        vm.$mount(`#${boxId}`);
    }
}