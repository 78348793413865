const ROUTE_ID_SMI_ANALITYCS  = 'smi';
const ROUTE_URL_SMI_ANALITYCS  = '/office/smianalitycs/';

const ROUTE_ID_COVID_CERT_CHECKING  = 'office_covidcertificatechecking';
const ROUTE_URL_COVID_CERT_CHECKING  = '/office/covidcertificatechecking/';

const ROUTE_ID_COVID_CERT_CHECKS  = 'office_covidcertificatechecking_history';
const ROUTE_URL_COVID_CERT_CHECKS  = '/office/covidcertificatechecking/history/';

const ROUTE_ID_THINGS  = 'office_things';
const ROUTE_URL_THINGS  = '/office/things';

const ROUTE_ID_RECHARGES  = 'office_recharges';
const ROUTE_URL_RECHARGES  = '/office/recharges/';

export const officeRoutes = [
    {
        name:      ROUTE_ID_SMI_ANALITYCS,
        path:      ROUTE_URL_SMI_ANALITYCS,
        component: () => import('lk_entries/Smi'),
    },
    {
        name:      ROUTE_ID_COVID_CERT_CHECKING,
        path:      ROUTE_URL_COVID_CERT_CHECKING,
        component: () => import('lk_entries/OfficeCovidcertificatechecking'),
    },
    {
        name:      ROUTE_ID_COVID_CERT_CHECKS,
        path:      ROUTE_URL_COVID_CERT_CHECKS,
        component: () => import('lk_entries/OfficeCovidcertificatecheckingHistory'),
    },
    {
        name:      ROUTE_ID_THINGS,
        path:      ROUTE_URL_THINGS,
        component: () => import('lk_entries/Things'),
    },
    {
        name:      ROUTE_ID_RECHARGES,
        path:      ROUTE_URL_RECHARGES,
        component: () => import('lk_entries/Recharges/Recharges'),
    },
    {
        name:      'office_registration',
        path:      '/office/registration',
        props: {isLkRegistration: true},
        component: () => import('entries/registration/Page'),
    },
];

export const router = new VueRouter({mode: 'history', routes: officeRoutes});
