import { render, staticRenderFns } from "./GlobalRouterWrapper.vue?vue&type=template&id=09940c23"
import script from "./GlobalRouterWrapper.vue?vue&type=script&lang=js"
export * from "./GlobalRouterWrapper.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports