import createApp from 'common_helpers/appRouter';
import {globalRouterInstanse} from 'entries/Router/GlobalRouterInstanse';
import GlobalRouterWrapper from 'entries/Router/GlobalRouterWrapper';

document.addEventListener(
    'DOMContentLoaded',
    () => {
        let boxId = 'GlobalRouter';
        if (!document.getElementById(boxId)) {
            boxId = 'lk_content';
        }
        createApp(boxId, GlobalRouterWrapper, globalRouterInstanse);
    }
);