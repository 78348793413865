import {officeRoutes} from 'lk_entries/router';

export const globalRoutes = [
    ...officeRoutes,
    {
        name: 'testing',
        path: '/information/testing',
        component: () => import('Pages/TestingPage'),
    },
    {
        name: 'main',
        path: '/',
        component: () => import('Pages/MainPage/MainPage'),
    },
    {
        name: 'alarm_demo_page',
        path: '/alarm',
        component: () => import('Pages/AlarmDemoPage'),
    },
    {
        name:      'registration',
        path:      '/users/registration',
        component: () => import('entries/registration/Page'),
    },
];